<style scoped lang="less">
  .cop-salary {
    &.flex {
      display: flex;
      flex-direction: column;
      .table-list {
        margin: 0 10px;
        background-color: #FFF;
        height: 0;
        flex: 1;
      }
    }
  }
  .title {
    margin: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #FFF;
    justify-content: space-between;
  }
</style>

<template>
  <div class="cop-salary flex">
    <div class="title">
      <fm-title style="margin: 0 10px;" title-text="人员信息维护" :note-text="'共' + dataList.length + '项'"></fm-title>
      <Row type="flex" justify="space-between" :wrap="false" :gutter="10" style="margin: 0 10px;flex: 1;">
        <i-col>
          <Row type="flex" :wrap="false" align="middle" :gutter="10">
            <i-col>
              <i-input placeholder="请输入姓名" search @on-blur="onSearch(null)" @on-search="onSearch(null)" @on-clear="onSearch(null)" clearable v-model="query.value">
                <Select slot="prepend" style="width: 100px" v-model="query.key">
                  <Option value="name">姓名</Option>
                  <Option value="phone">电话号码</Option>
                </Select>
              </i-input>
            </i-col>
            <i-col>类型: </i-col>
            <i-col>
              <Dropdown @on-click="type => onSearch({ type })">
                <Tag size="large" style="cursor: pointer;" color="primary" v-if="query.type === '1'">正式<Icon type="ios-arrow-down"></Icon></Tag>
                <Tag size="large" style="cursor: pointer;" color="blue" v-else-if="query.type === '2'">辅警<Icon type="ios-arrow-down"></Icon></Tag>
                <Tag size="large" style="cursor: pointer;" color="geekblue" v-else-if="query.type === '3'">退役<Icon type="ios-arrow-down"></Icon></Tag>
                <Tag size="large" style="cursor: pointer;" color="default" v-else>全部<Icon type="ios-arrow-down"></Icon></Tag>
                <DropdownMenu slot="list">
                  <DropdownItem :selected="query.type === 'all'" name="all">全部</DropdownItem>
                  <DropdownItem :selected="query.type === '1'" name="1">正式</DropdownItem>
                  <DropdownItem :selected="query.type === '2'" name="2">辅警</DropdownItem>
                  <DropdownItem :selected="query.type === '3'" name="3">退役</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </i-col>
          </Row>
        </i-col>
        <i-col>
          <Row style="margin-left: 10px;" type="flex" :gutter="10" :wrap="false">
            <i-col><Button icon="md-refresh-circle" :loading="loading.load" @click="loadData">刷新</Button></i-col>
            <i-col><Button icon="md-add-circle" type="primary" @click="addClick">新增</Button></i-col>
          </Row>
        </i-col>
      </Row>
    </div>
    <div class="table-list" ref="content">
      <Table ref="table" :loading="loading.load" :data="pageDataList" :columns="columns" :height="tableHeight">
        <template slot-scope="{ row }" slot="type">
          <Tag v-if="row.type === 1" class="noborder" color="primary">正式</Tag>
          <Tag v-if="row.type === 2" class="noborder" color="blue">辅警</Tag>
          <Tag v-if="row.type === 3" class="noborder" color="geekblue">退役</Tag>
        </template>
        <template slot-scope="{ row }" slot="action">
          <template>
            <Button icon="ios-brush" type="text" size="small" style="margin-right: 5px" @click="itemEdit(row)">修改</Button>
            <Poptip transfer confirm @on-ok="itemDel(row)" title="确定删除吗？">
              <Button icon="md-trash" size="small" type="text">删除</Button>
            </Poptip>
          </template>
        </template>
      </Table>
    </div>
    <div style="margin: 0 10px;padding: 10px;background-color: #FFF;">
      <Page @on-change="num => page.num = num" :total="dataList.length" :current="page.num" :page-size="page.size" show-total show-elevator />
    </div>
    <Drawer draggable :mask-closable="false" mask transfer :title="formData.id ? '修改' : '新增'" width="40" placement="right" closable v-model="status.drawer">
      <fm-form label-alone label-align="left">
        <fm-form-item label="姓名">
          <Input v-model="formData.name" />
        </fm-form-item>
        <fm-form-item label="用户">
          <Select clearable filterable v-model="formData.userId">
            <Option v-for="item in userList" :key="item.key" :value="item.key">{{ item.label }}</Option>
          </Select>
        </fm-form-item>
        <fm-form-item label="类型">
          <RadioGroup v-model="formData.type">
            <Radio :label="1">正式</Radio>
            <Radio :label="2">辅警</Radio>
            <Radio :label="3">退役</Radio>
          </RadioGroup>
        </fm-form-item>
        <fm-form-item label="工号">
          <Input v-model="formData.code" />
        </fm-form-item>
        <fm-form-item label="电话号码">
          <Input v-model="formData.phone" />
        </fm-form-item>
        <fm-form-item label="身份证">
          <Input v-model="formData.idNo" />
        </fm-form-item>
        <fm-form-item>
          <div style="display: flex;">
            <Button :loading="loading.update" style="flex: 1;" @click="status.drawer = false" type="default">取消</Button>
            <Button :loading="loading.update" style="margin-left: 10px;flex: 1;" @click="submit" type="info">确定{{(formData.id ? '修改' : '新增')}}</Button>
          </div>
        </fm-form-item>
      </fm-form>
    </Drawer>
  </div>
</template>

<script>
import { request } from '@/api'

function getDefault () {
  return {
    name: null,
    type: 1,
    code: null,
    phone: null,
    idNo: null,
    userId: null
  }
}

function getDefaultQuery () {
  return {
    key: 'name',
    value: null,
    type: 'all'
  }
}

export default {
  props: {
    type: { type: Number, default: 1 }
  },
  data () {
    return {
      dataList: [],
      formData: getDefault(),
      query: getDefaultQuery(),
      status: {
        drawer: false
      },
      page: {
        size: 10,
        num: 1
      },
      loading: {
        load: false,
        submit: false
      },
      tableHeight: null
    }
  },
  computed: {
    userList () {
      return this.$store.getters.userList
    },
    columns () {
      let columns = [
        { title: '姓名', key: 'name' },
        { title: '类型', key: 'type', slot: 'type' },
        { title: '工号', key: 'code' },
        { title: '电话号码', key: 'phone' },
        { title: '身份证', key: 'idNo' },
        { title: '用户名', key: 'userName' }
      ]
      return [
        ...columns,
        { key: 'action', title: '操作', slot: 'action', width: 190 }
      ]
    },
    pageDataList () {
      const start = (this.page.num - 1) * this.page.size
      const end = start + this.page.size
      return this.dataList.slice(start, end)
    }
  },
  methods: {
    addClick () {
      this.formData = getDefault()
      this.status.drawer = true
    },
    onSearch (params) {
      this.page.num = 1
      if (params) {
        Object.assign(this.query, params)
      }
      this.loadData()
    },
    itemEdit (item) {
      this.formData = JSON.parse(JSON.stringify(item))
      this.status.drawer = true
    },
    async submit () {
      this.loading.submit = true
      try {
        if (this.formData.id) {
          await request('/basis/hrms_wage_worker/' + this.formData.id, 'put', this.formData)
        } else {
          await request('/basis/hrms_wage_worker', 'post', this.formData)
          this.query = getDefaultQuery()
        }
        this.loading.submit = false
        this.$Message.success('操作成功')
        this.loadData()
        this.page.num = 1
        this.formData = getDefault()
        this.status.drawer = false
      } catch (e) {
        this.loading.submit = false
      }
    },
    async itemDel (item) {
      try {
        await request('/basis/hrms_wage_worker/' + item.id, 'delete')
        this.$Message.success('删除成功')
        this.loadData()
      } catch (e) {
        this.$Message.success('删除失败')
      }
    },
    async loadData () {
      this.loading.load = true

      const params = {}
      if (this.query.key && this.query.value) {
        params[this.query.key] = this.query.value
      }
      console.log(this.query.key, this.query.value, params)

      if (this.query.type !== 'all') {
        params.type = Number(this.query.type)
      }

      let dataList = await request('/basis/hrms_wage_worker', 'get', params)
      dataList.forEach(v => v.type = v.type ? Number(v.type) : v.type)
      this.dataList = dataList
      this.loading.load = false
    },
    resize () {
      this.tableHeight = this.$refs.content.offsetHeight
      this.page.size = Math.ceil((this.tableHeight - 40) / 48)
    }
  },
  async mounted () {
    console.log(this.$route)
    window.addEventListener('resize', this.resize)
    this.resize()
    this.loadData()
    this.$store.dispatch('loadUserList')
  },
  destroyed () {
    this.dataList = []
    window.removeEventListener('resize', this.resize)
  }
}
</script>
